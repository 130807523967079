<template>
  <div class="index">
    <moModel3 :moData="moData"></moModel3>
    <oAds></oAds>
  </div>
</template>

<script>
import { Button } from "vant";
import { post } from "@get/http.js";
import moModel3 from "../home/components/models";
import oAds from "../home/view/ads/index.vue";
import { getQueryVariable } from "@get/util";

export default {
  name: "HomeIndex",
  components: {
    vanButton: Button,
    moModel3,
    oAds,
  },
  data() {
    return {
      moData: [],
      docu_title: "",
    };
  },
  created() {
    console.log();
    this.getIndexModelData();
  },
  watch: {},
  methods: {
    async getIndexModelData() {
      /***
       * @method getIndexModelData 获取首页模块数据
       */

      try {
        let store_id = getQueryVariable("store_id") || 2;
        let id = 134;
        let page_type = 2;

        let {
          page_config,
          remark,
          share_desc,
          share_icon,
          share_title,
        } = await post("/index/mofang", {
          data: {
            store_id,
            id,
            page_type,
            inviter_level:
              this.$store.state?.user?.info["19jiang_inviter_level_id"] || 0,
          },
        });
        if (remark) {
          this.$route.meta.title = remark;
          document.title = remark;
        }
        if (share_desc && share_desc) {
          wx.ready(function() {
            let dWechatShareInfo = {
              //微信分享信息
              title: share_title,
              link: window.location.href,
              imgUrl:
                share_icon ||
                "http://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021042919105559851.png",
              desc: share_desc || "专注于为美业人提供最独具匠心的产品和服务",
            };
            console.log(dWechatShareInfo);
            wx.updateAppMessageShareData(dWechatShareInfo);
            wx.onMenuShareTimeline(dWechatShareInfo);
            wx.onMenuShareAppMessage(dWechatShareInfo);
            wx.updateTimelineShareData(dWechatShareInfo);
          });
        }

        this.moData = page_config;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
body,
html {
  // overflow-x: hidden;
}
.index {
  margin-bottom: 70px;
}
</style>
